import React from "react";
import SEO from '../components/seo';

const Wholesale = () => (
	<div className="wrapper-main">
	<SEO title="Wholesale information and registration form" />
		<iframe
			src="https://docs.google.com/forms/d/e/1FAIpQLSd2QWi_DvsYLVTwqbmpFYjhzO8bu6GMeMRfyRfUcytTP-FlDg/viewform?embedded=true"
			width="100%"
			height="1400"
			frameborder="0"
			marginheight="0"
			marginwidth="0"
		>
			Loading...
		</iframe>
	</div>
);

export default Wholesale;
